import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Quiz App Template | Quiz App Design Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/quiz-mobile-app/"
    metaDescription="Design your own quiz app for mobile with our stunning quiz app template. Use the design as it is or adapt it to suit your project vision. Try now."
    description="
    h2:Bring your quiz app design to life with our mobile quiz app template
    <br/>
    Designing your own quiz app for mobile devices? Check out our brand-new quiz app design template with Uizard Pro and bring your idea to life. Work alone, or invite team members to collaborate in real-time, the opportunities are endless. <a:https://uizard.io/prototyping/>Prototyping</a> made easy with Uizard.
    <br/>
    h3:Become a design whiz with the power of AI
    <br/>
    Transform the way you design with the power of AI! Our <a:https://uizard.io/design-assistant/>AI-powered Design Assistant</a> comes packed with features to make designing your app easier and faster than ever before. Try updating the quiz app template with AI-generated text using Text Assistant or update the theme of the template with Theme Generator, the choice is yours.
    <br/>
    h3:Endless customization options to make your quiz app design sparkle
    <br/>
    As with all our <a:https://uizard.io/templates/mobile-app-templates/>app templates</a>, our mobile quiz app template is easily customizable. Use drag-and-drop UI components, update color schemes, or even add new screens entirely. Designing your app prototype has never been so easy.
    "
    pages={[
      "User onboarding flow with intuitive user data input from and sign-up CTA",
      "Quiz browser home screen with quiz info screen",
      "Quiz playthrough mockup demonstrating core functionality",
      "Social sharing feature mockups",
    ]}
    projectCode="YyAgdQ0lMpI0n6g0OwwQ"
    img1={data.image1.childImageSharp}
    img1alt="quiz mobile app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="quiz mobile app design template profile screen"
    img3={data.image3.childImageSharp}
    img3alt="quiz mobile app design template leaderboard screen"
    img4={data.image4.childImageSharp}
    img4alt="quiz mobile app design template homescreen"
    img5={data.image5.childImageSharp}
    img5alt="quiz mobile app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/quiz-mobile-app/quiz-app-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/quiz-mobile-app/quiz-app-mobile-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/quiz-mobile-app/quiz-app-mobile-leaderboard.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/quiz-mobile-app/quiz-app-mobile-home.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/quiz-mobile-app/quiz-app-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
